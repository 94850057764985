<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">Precon Manager</v-card-title>
        <v-card-text>
            <v-form ref="preconManagerInfo">
                <v-autocomplete
                    @change="deleteSearch"
                    :search-input.sync="search"
                    label="Precon Manager"
                    v-model="preconManagerInfo"
                    :items="users"
                    item-text="name"
                    chips
                    item-value="id"
                    :rules="[rules.required]"
                >
                </v-autocomplete>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn text color="secondary" @click="closeDialog">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="update" text color="error" :loading="loading">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import { loadImage } from '@/helpers/imageHandler'
import API from '@/services/api'

export default {
    name: 'ChangePreconManagerLeads',
    props: {
        leadId: {
            type: String,
            required: true,
            default: () => {
                return ''
            },
        },
        lead: {
            type: Object,
            required: true,
            default: () => {
                return {}
            },
        },
    },
    data: () => {
        return {
            preconManagerInfo: null,
            search: '',
            loading: false,
            errMessage: '',
            companyId: JSON.parse(localStorage.getItem('company')),
            folderUsersPictures: 'users_pictures',
            users: [],
            rules: {
                required: v => !!v || 'Required',
            },
        }
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            const preconManagerInfo = this.users.find(
                u => u.id == this.lead.preconManagerId
            )
            this.preconManagerInfo = preconManagerInfo ? preconManagerInfo : ''
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeDialog() {
            this.$emit('closeChangePreconManager')
        },
        deleteSearch() {
            this.search = ''
        },
        async update() {
            try {
                this.loading = true
                this.errMessage = ''
                const user = this.users.find(
                    u => u.id === this.preconManagerInfo
                )
                if (user) {
                    if (user.picture) {
                        user.profilePicURL = await loadImage(
                            `${this.companyId}/${this.folderUsersPictures}`,
                            user.picture
                        )
                    }
                    if (
                        !this.lead.collaborators.includes(
                            this.preconManagerInfo
                        )
                    ) {
                        this.lead.collaborators.push(this.preconManagerInfo)
                    }
                    this.lead.collaborators = this.lead.collaborators.filter(
                        collaborator =>
                            collaborator != this.lead.preconManagerId
                    )
                    await API.updateLead({
                        id: this.leadId,
                        preconManagerId: this.preconManagerInfo,
                        collaborators: this.lead.collaborators,
                    })
                    this.$emit('replacePreconManager', {
                        user,
                        collaborators: this.lead.collaborators,
                    })
                    this.$emit('closeChangePreconManager')
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
